<template>
  <transition name="fade" mode="out-in">
    <BaseIconMessage
      v-if="success"
      :border="false"
      icon="fa-check-circle"
      :message="$t('message_added_to_waitlist')"
      text-color="text-notification-success"
    />
    <section v-else>
      <h3 class="tg-mobile-header-3 lg:tg-desktop-header-3">
        {{ $t('join_waitlist') }}?
      </h3>

      <FormulateForm
        #default="{ isLoading, hasErrors }"
        v-model="candidate"
        name="PropertyVisitJoinWaitlist"
        @submit="submit"
      >
        <FormulateInput
          type="text"
          name="first_name"
          :label="$t('first_name')"
          validation="required:trim"
          :show-required-label="true"
        />
        <FormulateInput
          type="text"
          name="last_name"
          :label="$t('last_name')"
          validation="required:trim"
          :show-required-label="true"
        />
        <FormulateInput
          type="select"
          name="language"
          :label="$t('preferred_language')"
          :placeholder="$t('select_language')"
          :value="$i18n.locale"
          :options="langOptions"
          :show-required-label="true"
          validation="required"
        />
        <FormulateInput
          v-model.trim="candidate.email"
          type="email"
          name="email"
          :label="$t('email_label')"
          validation="bail|required|email"
          :show-required-label="true"
        />
        <FormulateInput
          type="dial_code_tel"
          name="phone_number_mobile"
          :label="$t('mobile')"
          :show-required-label="true"
          validation="bail|required|phone"
        />
        <div class="my-7.5">
          <FormulateErrors />
          <FormulateInput
            type="submit"
            :disabled="hasErrors || isLoading"
            :input-class="['w-full lg:w-auto']"
            outer-class="my-0"
          >
            <i
              :class="[
                'far mr-2',
                isLoading ? 'fa-spinner-third animate-spin' : 'fa-check'
              ]"
            />
            {{ $t('join_waitlist') }}
          </FormulateInput>
        </div>
      </FormulateForm>
    </section>
  </transition>
</template>

<script>
import { createPropertyVisitWaitlistEntry } from '@/services/calendarService';
import { parsePhone, langOptions } from '@/helpers';

export default {
  name: 'PropertyVisitJoinWaitlist',
  data() {
    return {
      langOptions,
      propertyId: this.$route.params.propertyId,
      candidate: {},
      success: false
    };
  },
  methods: {
    submit(data) {
      let candidate = { ...data };
      candidate.phone_number_mobile = parsePhone(candidate.phone_number_mobile);

      return createPropertyVisitWaitlistEntry(this.propertyId, { candidate })
        .then(() => {
          this.success = true;
          this.$store.dispatch('snackbar/show', {
            type: 'success',
            messageBold: 'Success!',
            message: this.$t('message_added_to_waitlist')
          });
        })
        .catch(error => {
          this.$formulate.handle(error, 'PropertyVisitJoinWaitlist');
        });
    }
  }
};
</script>
